<script>
    import { removeDuplicates } from "@/utils";

    export default {
        name: "ProductColorList",
        props: {
            currentOffer: Object,
            offers: Array,
            sizeType: {
                default: "sm",
                type: String,
            },
        },
        data() {
            return {
                currentColor: this.currentOffer.color?.id,
            };
        },
        computed: {
            listColors() {
                return removeDuplicates(
                    this.offers
                        .filter((offer) => offer.color && offer.available)
                        .map((offer) => offer.color)
                );
            },
        },
        methods: {
            selectColor(id) {
                this.currentColor = id;
                this.$emit("select-color", id);
            },
            createBgStyle(codeColor) {
                return codeColor === "ffffff"
                    ? `background-color: #${codeColor}; border: 1px solid #bdbdbd;`
                    : `background-color: #${codeColor};`;
            },
        },
    };
</script>

<template>
    <div class="c-color-list">
        <button
            class="c-color-list__item outline"
            :class="{
                'is-active': currentColor === color.id,
            }"
            v-for="color in listColors"
            :key="color.id"
            @click="selectColor(color.id)"
        >
            <span
                class="c-color-list__item-circle"
                :style="createBgStyle(color.code)"
                role="img"
                :alt="color.name + ' цвет'"
            ></span>
        </button>
    </div>
</template>

<style lang="scss">
    .c-color-list {
        display: flex;
        flex-wrap: wrap;
    }

    .c-color-list__item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        @include box(24px);
        border: 2px solid transparent;
        border-radius: 50%;
        transition: box-shadow 0.2s ease-in, border-color 0.2s ease-in;
        cursor: pointer;
        &.is-active {
            pointer-events: none;
            border-color: var(--primary);
        }
        @include bp($bp-desktop-sm) {
            &:hover {
                .c-color-list__item-circle {
                    transform: scale(1.05);
                }
            }
        }
    }

    .c-color-list__item-circle {
        @include box(16px);
        border-radius: 50%;
        transition: 0.2s ease all;
    }
</style>
