<script>
    export default {
        name: "ProductPrice",
        props: {
            oldPrice: Number,
            price: Number,
            discountCount: Number,
            isSmallSize: Boolean,
        },
        computed: {
            isDiscount() {
                return this.discountCount;
            },
        },
    };
</script>

<template>
    <div
        class="product-price"
        :class="{
            'product-price--sm': isSmallSize,
        }"
    >
        <div class="product-price__wrap">
            <div
                class="product-price__number product-price__number--current"
                :class="{
                    'product-price__number--discount': isDiscount,
                }"
            >
                {{ price | formatPrice }}₽
            </div>
            <div v-if="isDiscount" class="product-price__number product-price__number--old">
                {{ oldPrice | formatPrice }}₽
            </div>
            <div v-if="isDiscount && !isSmallSize" class="product-price__label">
                -{{ discountCount }}%
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .product-price {
        position: relative;
    }

    .product-price__label {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-left: 12px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        background-color: var(--danger);
    }

    .product-price__wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    .product-price__number {
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        color: var(--primary-text);
    }

    //
    // --mod
    //

    .product-price__number--current {
        margin-right: 12px;
    }

    .product-price__number--discount {
        color: var(--danger);
    }

    .product-price__number--old {
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
        color: var(--primary-text--lighter);
        text-decoration: line-through;
    }

    .product-price--sm {
        .product-price__label {
            display: none;
        }

        .product-price__number {
            font-size: 20px;
            line-height: 24px;
        }

        .product-price__number--current {
            margin-right: 8px;
        }

        .product-price__number--old {
            font-size: 15px;
            line-height: 20px;
        }
    }
</style>
